import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M7.9375 26H0.0823866V0.545454H8.28551C10.7547 0.545454 12.8677 1.05504 14.6243 2.07422C16.3809 3.08511 17.7274 4.5393 18.6637 6.43679C19.6 8.32599 20.0682 10.5881 20.0682 13.223C20.0682 15.8745 19.5959 18.1573 18.6513 20.0714C17.7067 21.9772 16.3312 23.4438 14.5249 24.4712C12.7185 25.4904 10.5227 26 7.9375 26ZM3.16477 23.2656H7.73864C9.84328 23.2656 11.5875 22.8596 12.9712 22.0476C14.355 21.2356 15.3866 20.0797 16.0661 18.5799C16.7455 17.0801 17.0852 15.2945 17.0852 13.223C17.0852 11.1681 16.7496 9.39903 16.0785 7.91584C15.4073 6.42436 14.4047 5.28089 13.0707 4.48544C11.7366 3.6817 10.0753 3.27983 8.08665 3.27983H3.16477V23.2656Z"
          fill="currentColor"
          transform="translate(31, 32)"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
