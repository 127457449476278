import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { quote } from '@config';
import { Side } from '@components';

const StyledQuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 90px;
    margin: 0 auto;
    background-color: var(--light-slate);
  }

  span {
    margin: 20px auto;
    padding: 10px;
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    line-height: var(--fz-lg);
    letter-spacing: 0.1em;
    writing-mode: vertical-rl;
    cursor: default;
  }
`;

const Quote = ({ isHome }) => (
  <Side isHome={isHome} orientation="right">
    <StyledQuoteWrapper>
      <span>{quote}</span>
    </StyledQuoteWrapper>
  </Side>
);

Quote.propTypes = {
  isHome: PropTypes.bool,
};

export default Quote;
